import React from 'react'
import './SolutionsList.css'

const SolutionsList = () => {

  const solutions = [
    'Кастомные EPR-решения',
    'Системы управления контентом и оборудованием',
    'Цифровизация рутинных процессов предприятия',
    'Консалтинг',
    'Консалтинг',
  ]

  return (
    <section className='solutionsList container'>
        {
            solutions.map((item, i) =>
              <article key={i} className={`solution ${(i+1) % 4 < 2 ? '' : 'solution-big'}`}>
                <h3>{item}</h3>
                <div className='solution__img'></div>
              </article>
            )
        }
    </section>
  )
}

export default SolutionsList