import React from 'react'
import './Strengths.css'

const Strengths = ({ data, isReview }) => {

  return (
    <section className='strengths container'>
            <div className='strengths__header'>
                <h2 className='strengths__header-title'>{data.head.title}</h2>
                <p className='strengths__header-text' style={{color: `${isReview ? '#BDBDBD' : ''}`}}>{data.head.text}</p>
            </div>
            <div className='strengths__list'>
                {data.cards.map((item, i) => 
                    <div className={`strength__card ${isReview ? 'clientsReview' : ''}`} key={i}>
                        <div className='strength__card-head'>
                            <div className='strength__card-icon'
                                style={{background: `url('${item.icon}') center no-repeat`,
                                backgroundColor: '#E9E9E9',
                                backgroundSize: `${isReview ? '40px' : '24px'}`}}>
                            </div>
                            <h3>{item.title}</h3>
                            {isReview && <p className='strength__card-text'>{item.text}</p>}
                        </div>
                        {!isReview && <p className='strength__card-text'>{item.text}</p>}
                        {
                            isReview && 
                            <div className='strength__card-client'>                                
                                <h4>{item.name}</h4>
                                <p>{item.position}</p>
                            </div>
                        }
                    </div>
                )}
            </div>
    </section>
  )
}

export default Strengths