import React from 'react'
import { Link } from 'react-router-dom'
import './TopBlock.css'
import logo from '../../../Media/logo.svg'
import Navigation from '../../Common/Navigation/Navigation'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import porsche from '../../../Media/porsche.png'
import iconXR from '../../../Media/IconXR.svg'
import iconOnline from '../../../Media/IconOnline.svg'
import iconInteractive from '../../../Media/IconInteractive.svg'
import iconBusiness from '../../../Media/IconBusiness.svg'
import partnerLogos from './Utils/PartnerLogos.js'

const TopBlock = ({isMobile}) => {

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <section className='topBlock container'>
                <div className='topBlock__wrapper'>
                    <div className='topBlock__banner'>
                        <div className='topBlock__banner-left'>
                            {!isMobile && <Link to='/'><img src={logo} className='topBlock__logo' alt='logo'/></Link>}
                            <div className='topBlock__titles'>
                                <h1 className='topBlock__title'>IT-спецназ<br/>из России</h1>
                                <p className='topBlock__label'>Решаем задачи вашего бизнеса<br/>в цифровой среде</p>
                            </div>
                        </div>
                        <div className='topBlock__banner-right'>
                            <div className='topBlock__nav-wrapper'>
                                {!isMobile && <Navigation />}
                            </div>
                            <div className='topBlock__stamp'></div>
                        </div>
                    </div>


                    <div className='topBlock__partners'>
                        <h2 className='topBlock__partners-title'>Партнеры</h2>
                        <div className='topBlock__ticker'>
                            <div class="topBlock__ticker-items marquee">
                                {partnerLogos.map((i, index) => <img src={i} alt='1' key={index} class="topBlock__ticker-item"/>)}
                            </div>
                            <div aria-hidden="true" class="topBlock__ticker-items marquee">
                                {partnerLogos.map((i, index) => <img src={i} alt='1' key={index} class="topBlock__ticker-item"/>)}
                            </div>
                        </div>
                        <p className='topBlock__partners-label'>Нашими услугами пользуются</p>
                    </div>


                    <div className='topBlock__slider'>
                        <h2 className='topBlock__slider-title'>Наши компетенции</h2>                       
                        <Slider {...settings}>
                            <div className='topBlock__slide'>
                                <div className='topBlock__slide-card'>
                                    <div className='topBlock__slide-content'>
                                        <div className='topBlock__slide-icon' style={{background: `url(${iconInteractive}) center no-repeat`, backgroundSize: '20px 20px', backgroundColor: '#141414'}}></div>
                                        <div className='topBlock__slide-title'>
                                            <h3>Pattern Interactive</h3>
                                            <p>Интерактив для мероприятий, музеев и выставок</p>
                                        </div>
                                        <div className='topBlock__slide-case'>
                                            <p className='topBlock__slide-case-count'>13</p>
                                            <p className='topBlock__slide-case-name'>кейсов</p>
                                        </div>
                                    </div>
                                </div>
                                <img src={porsche} alt='1'className='topBlock__slide-img' />
                            </div>
                            <div className='topBlock__slide'>
                                <div className='topBlock__slide-card'>
                                    <div className='topBlock__slide-content'>
                                        <div className='topBlock__slide-icon' style={{background: `url(${iconBusiness}) center no-repeat`, backgroundSize: '20px 20px', backgroundColor: '#141414'}}></div>
                                        <div className='topBlock__slide-title'>
                                            <h3>Pattern Business</h3>
                                            <p>Cистемы управления, ERP, автоматизация и цифровизация</p>
                                        </div>
                                        <div className='topBlock__slide-case'>
                                            <p className='topBlock__slide-case-count'>10</p>
                                            <p className='topBlock__slide-case-name'>кейсов</p>
                                        </div>
                                    </div>
                                </div>
                                <img src={porsche} alt='1'className='topBlock__slide-img' />
                            </div>
                            <div className='topBlock__slide'>
                                <div className='topBlock__slide-card'>
                                    <div className='topBlock__slide-content'>
                                        <div className='topBlock__slide-icon' style={{background: `url(${iconXR}) center no-repeat`, backgroundSize: '20px 20px', backgroundColor: '#141414'}}></div>
                                        <div className='topBlock__slide-title'>
                                            <h3>Pattern XR</h3>
                                            <p>Проекты с технологиями AR/VR</p>
                                        </div>
                                        <div className='topBlock__slide-case'>
                                            <p className='topBlock__slide-case-count'>9</p>
                                            <p className='topBlock__slide-case-name'>кейсов</p>
                                        </div>
                                    </div>
                                </div>
                                <img src={porsche} alt='1'className='topBlock__slide-img' />
                            </div>
                            <div className='topBlock__slide'>
                                <div className='topBlock__slide-card'>
                                    <div className='topBlock__slide-content'>
                                        <div className='topBlock__slide-icon' style={{background: `url(${iconOnline}) center no-repeat`, backgroundSize: '20px 20px', backgroundColor: '#141414'}}></div>
                                        <div className='topBlock__slide-title'>
                                            <h3>Pattern Online</h3>
                                            <p>Разработка веб и мобильных приложений</p>
                                        </div>
                                        <div className='topBlock__slide-case'>
                                            <p className='topBlock__slide-case-count'>25</p>
                                            <p className='topBlock__slide-case-name'>кейсов</p>
                                        </div>
                                    </div>
                                </div>
                                <img src={porsche} alt='1'className='topBlock__slide-img' />
                            </div>
                        </Slider>
                    </div>
                </div>
        </section>
  )
}

export default TopBlock