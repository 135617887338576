import React from 'react'
import CaseCard from '../CaseCard/CaseCard'
import './ReadyMechanics.css'

const ReadyMechanics = () => {

  const temp = [1, 2, 3]

  return (
    <section className='readyMechanics container'>
      <div className='readyMechanics__wrapper'>
        <h2 className='readyMechanics__title'>Готовые механики,</h2>
        <p className='readyMechanics__caption'>которые мы можем адаптировать для ваших задач</p>
        <div className='readyMechanics__list'>
          {
            temp.map(() => 
              <div className='readyMechanics__list-item'>
                <CaseCard bgColor={'#fff'} type='readyMechanics' />
              </div>
            )
          }
        </div>
      </div>
    </section>
  )
}

export default ReadyMechanics