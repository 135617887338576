import React, {useRef} from 'react'
import './DirectionsList.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import testCar from '../../../Media/TestCar.png'
import testPanda from '../../../Media/TestPanda.png'
import testRosatom from '../../../Media/TestRosatom.png'

const DirectionsList = () => {

  const temp = [
    {
        title: 'Мобильные игры',
        text: 'Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»',
        images: [testCar, testPanda, testRosatom]
    },
    {
        title: 'Лендинги с игровым функционалом',
        text: 'Свяжитесь с нами и мы ответим на любые вопросы.',
        images: [testPanda, testCar, testRosatom]
    },
    {
        title: 'Edutainment',
        text: 'Перед задача нами стояла задача разработать мобильное приложение',
        images: [testRosatom, testCar, testPanda]
    }
  ]

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    arrows: false,
    autoplay: false,
    touchMove: false,
    speed: 600,
    cssEase: "linear",
  };


  const directionSlider = useRef([])
  directionSlider.current = []


  const addToRefs = (el) => {
    if(el && !directionSlider.current.includes(el)) {
        directionSlider.current.push(el)
    }
  }


  function next({i}) {
    directionSlider.current[i].slickNext()
  }

  function previous({i}) {
    directionSlider.current[i].slickPrev()
  }


  return (
    <section className='directionsList container'>
        {
            temp.map((item, i) => 
                <article className='direction' key={item.title}>
                    <div className='direction__name'>
                        <h2>{item.title}</h2>
                        <p>{item.text}</p>
                    </div>
                    <div className='direction__slider'>
                        <Slider {...settings} ref={addToRefs}>
                            {
                                item.images.map((img, ind) => 
                                    <div className='direction__slide' key={ind}>
                                        {/* <img src={`${img}`} alt={`${ind}`}/> */}
                                        <img src={img} alt={ind}/>
                                    </div>
                                )
                            }
                        </Slider>
                        <div className='direction__slider-arrows'>
                            <div className='direction__slider-arrow-left' onClick={() => previous({i})}></div>
                            <div className='direction__slider-arrow-right' onClick={() => next({i})}></div>
                        </div>
                    </div>
                </article>
            )
        } 
    </section>
  )
}

export default DirectionsList