import React from 'react'
import CompetenceHead from '../Common/СompetenceHead/СompetenceHead'
import OnlineIcon from '../../Media/IconOnlineBlack.svg'
import WriteUs from '../Common/WriteUs/WriteUs'
import Advantages from '../Common/Advantages/Advantages'
import DirectionsList from '../Common/DirectionsList/DirectionsList'

const Online = ({ isMobile }) => {
  return (
    <>
        <CompetenceHead
            title={'Pattern Online'}
            description={'Разрабатываем мобильные приложения для детей и взрослых'}
            icon={OnlineIcon}
            color={'#A966FF'}
            topLabel={'Опыт с 2017 года'}
            bottomLabel={'Более 70% клиентов обращаются к нам повторно'}
            isMobile={isMobile}
        />
        <Advantages />
        <DirectionsList />
        <WriteUs isMobile={isMobile} />
    </>
  )
}

export default Online