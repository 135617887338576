import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Header.css'
import logo from '../../../Media/logo.svg'

const Header = ({isMobile}) => {

  const [isActive, setIsActive] = useState(false)
  const [hasHeaderShown, setHasHeaderShown] = useState(false)


  window.onscroll = function() {
    let top = window.scrollY
    setIsActive(top >= 109)
  }


  useEffect(() => {
    if(window.scrollY === 0 && !isActive) {
      setHasHeaderShown(false)
    } else {
      setHasHeaderShown(true)
    }
  }, [isActive])


  return (
    <header className={`header ${isActive ? 'active' : hasHeaderShown ? 'hidden' : ''}`}>
      <div className='container'>
        <div className='header__content'>
          <Link to='/' className='header__logo-link'><img src={logo} className='header__logo' alt='logo'/></Link>
          {
            !isMobile && 
            <>
              <nav>
                <ul className='header__nav-list'>
                  <li className='header__nav-list-item'><NavLink to='/'>О нас</NavLink></li>
                  <li className='header__nav-list-item'><NavLink to='/contacts'>Контакты</NavLink></li>
                </ul>
              </nav>
              <nav>
                <ul className='header__service-list'>
                  <li className='header__service-list-item'>Услуги:</li>
                  <li className='header__service-list-item'><NavLink to='/interactive'>Interactive</NavLink></li>
                  <li className='header__service-list-item'><NavLink to='/business'>Business</NavLink></li>
                  <li className='header__service-list-item'><NavLink to='/xr'>XR</NavLink></li>
                  <li className='header__service-list-item'><NavLink to='/online'>Online</NavLink></li>
                </ul>
              </nav>
              <Link to='/' className='header__presentation'>Презентация</Link>
            </>
          }
        </div>
      </div>
    </header>
  )
}

export default Header