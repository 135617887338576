import moex from '../TestData/ClientReviewIcons/MoexIcon.svg'
import gubaha from '../TestData/ClientReviewIcons/GubahaIcon.svg'
import rostech from '../TestData/ClientReviewIcons/RostechIcon.svg'

const clientReviewsData = {
    head: {
        title: 'Отзывы клиентов',
        text: `К сожалению, мы не можем показать здесь кейсы, так как многие из них находятся
                под Договором о неразглашении. Но отзывы наших клиентов говорят больше, чем
                любые визуальные представления проектов.`
    },
    cards: [
        {
            title: 'Нартис',
            icon: moex,
            text: `Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»,
                    демонстрирующее проект АЭС «АККУЮ» и устройство ядерного реактора технологии
                    ВВЭР-1200 при помощи технологий дополненной.`,
            name: 'Екатерина Перова',
            position: 'СЕО'
        },
        {
            title: 'Мосбиржа',
            icon: moex,
            text: `Павильон «Цветоводство» (ВДНХ), Музей Победы на Поклонной горе – экспозиции
                    «Подвиг народа» и «Битва за Москву», Экоцентр на Яузе, шоу-рум Алмаз-Антей.`,
            name: 'Екатерина Перова',
            position: 'СЕО'
        },
        {
            title: 'PBN',
            icon: moex,
            text: `Павильон «Цветоводство» (ВДНХ), Музей Победы на Поклонной горе – экспозиции
                    «Подвиг народа» и «Битва за Москву», Экоцентр на Яузе, шоу-рум Алмаз-Антей.`,
            name: 'Екатерина Перова',
            position: 'СЕО'
        },
        {
            title: 'ВК Губаха',
            icon: gubaha,
            text: `Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»,
                    демонстрирующее проект АЭС «АККУЮ» и устройство ядерного реактора технологии
                    ВВЭР-1200 при помощи технологий дополненной.`,
            name: 'Екатерина Перова',
            position: 'СЕО'
        },
        {
            title: 'Музей ВМФ',
            icon: moex,
            text: `Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»,
                    демонстрирующее проект АЭС «АККУЮ» и устройство ядерного реактора технологии
                    ВВЭР-1200 при помощи технологий дополненной.`,
            name: 'Екатерина Перова',
            position: 'СЕО'
        },
        {
            title: 'РОСТЕХ',
            icon: rostech,
            text: `Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»,
                    демонстрирующее проект АЭС «АККУЮ» и устройство ядерного реактора технологии
                    ВВЭР-1200 при помощи технологий дополненной.`,
            name: 'Екатерина Перова',
            position: 'СЕО'
        },
        {
            title: 'Амириг',
            icon: moex,
            text: `Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»,
                    демонстрирующее проект АЭС «АККУЮ» и устройство ядерного реактора технологии
                    ВВЭР-1200 при помощи технологий дополненной.`,
            name: 'Екатерина Перова',
            position: 'СЕО'
        },
    ]
}

export default clientReviewsData