import React from 'react'
import './Advantages.css'
import clock from '../../../Media/ClockIcon.svg'
import checkMark from '../../../Media/CheckMarkIcon.svg'
import navigation from '../../../Media/NavigationIcon.svg'
import price from '../../../Media/PriceIcon.svg'

const Advantages = () => {

  const andvantagesData = [
    {title: 'В короткие сроки', icon: clock},
    {title: 'Надежно', icon: checkMark},
    {title: 'Стильный продуманный дизайн', icon: navigation},
    {title: 'Разумные цены', icon: price}
  ]

  return (
    <section className='atvantages container'>
        <div className='atvantages__wrapper'>
            {
                andvantagesData.map((item) => 
                    <div className='atvantage' key={item.title}>
                        <h3>{item.title}</h3>
                        <div className='atvantage__icon' style={{background: `url('${item.icon}')  center no-repeat`, backgroundSize: 24}}></div>
                    </div>
                )
            }
        </div>
    </section>
  )
}

export default Advantages