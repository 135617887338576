import React from 'react'
import { Link } from 'react-router-dom'
import './CaseList.css'
import CaseCard from '../CaseCard/CaseCard'

const CaseList = ({ isMobile }) => {

  const temp = [1, 2, 3]

  return (
    <section className='caseList container'>
        {
            //возможно лучше заменить условние в article на css свойство для потомков caseList (even, odd)
            temp.map((i) => 
                <article className={`caseList__item ${i % 2 === 0 ? 'even-item' : ''}`}>
                    <div className='caseList__item-category'>
                        <div className='caseList__item-heading'>
                            <h2 className='caseList__item-title'>Интерактивная презентация и тач-стол</h2>
                            <p className='caseList__item-caption'>Свяжитесь с нами и мы ответим на любые вопросы.</p>
                        </div>
                        <div className='caseList__item__cutaway desctop-tablet'>
                            <p>Скачать приложение Росатом КВИЗ</p>
                            <Link to='/' className='caseList__item__qr-code'></Link>
                        </div>
                    </div>
                    <div className='caseList__item-case'>
                        <CaseCard bgColor={'#E9E9E9'}/>
                        <div className='caseList__item__cutaway mobile'>
                            <p>Скачать приложение Росатом КВИЗ</p>
                            <Link to='/' className='caseList__item__qr-code'></Link>
                        </div>
                    </div>
                </article>
            )
        }
    </section>
  )
}

export default CaseList