import React from 'react'
import CompetenceHead from '../Common/СompetenceHead/СompetenceHead'
import InteractiveIcon from '../../Media/IconInteractiveBlack.svg'
import CaseList from '../Common/CaseList/CaseList'
import ReadyMechanics from '../Common/ReadyMechanics/ReadyMechanics'
import Footer from '../Common/Footer/Footer'

const Interactive = ({ isMobile }) => {
  return (
    <>
        <CompetenceHead
            title={'Pattern Interactive'}
            description={'Разрабатываем интерактивные инсталляции для музеев, выставок, шоу-румов и мероприятий'}
            icon={InteractiveIcon}
            color={'#CEEB1A'}
            topLabel={'Опыт с 2017 года'}
            bottomLabel={'Более 70% клиентов обращаются к нам повторно'}
            isMobile={isMobile}
        />
        <CaseList isMobile={isMobile}/>
        <ReadyMechanics />
    </>
  )
}

export default Interactive