import React from 'react'
import './CaseInfo.css'

const CaseInfo = ({sMobile}) => {
  return (
    <section className='caseInfo container'>
        <div className='caseInfo__wrapper'>
            <div className='caseInfo__main'>
                <div className='caseInfo__main-inf'>
                    <div>
                        <h1>Porsche</h1>
                        <h2>Викторина</h2>
                    </div>
                    <div className='caseInfo__rating'><div className='caseInfo__rating-star'></div>4.6</div>
                </div>
                <p className='caseInfo__year'>2021</p>
            </div>
            <div className='caseInfo__more'>
                <div className='caseInfo__task'>
                    <h2>Задача:</h2>
                    <p>
                        Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»,
                        демонстрирующее проект АЭС «АККУЮ» и устройство ядерного реактора технологии
                        ВВЭР-1200 при помощи технологий дополненной.
                    </p>
                </div>
                <div className='caseInfo__term'>
                    <h2>Дней на реализацию:</h2>
                    <p>24</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default CaseInfo