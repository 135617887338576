import React from 'react'
import FeedbackForm from '../../Common/FeedbackForm/FeedbackForm'
import './ContactBlock.css'

const ContactBlock = ({isMobile}) => {
  return (
    <section className='contactBlock container'>
        <div className='contactBlock__wrapper'>
            <div className='contactBlock__card'>
                <div className='contactBlock__heading'>
                    <h2 className='contactBlock__title'>Контакты</h2>
                    <p className='contactBlock__text'>
                        Получите предварительную калькуляцию вашего проекта в течение 12 часов:
                    </p>
                </div>
                <div className='contactBlock__inf'>
                    <div className='contactBlock__contacts'>
                        <a href='tel: +79099498853' className='contactBlock__phone'>+7 909 949 88 53</a>
                        <a href='mailto: pro@patterndigital.com' className='contactBlock__email'>pro@patterndigital.com</a>
                    </div>
                    <div className='contactBlock__cutaway'>
                        {
                            isMobile ?
                            <p>Наша визитка</p> :
                            <p>Элетронная визитка Pattern digital</p>

                        }
                        <a href='#' className='contactBlock__qr-code'></a>
                    </div>
                </div>
            </div>
            <FeedbackForm />
        </div>
    </section>
  )
}

export default ContactBlock