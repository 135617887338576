import React from 'react'
import CompetenceHead from '../Common/СompetenceHead/СompetenceHead'
import BusinessIcon from '../../Media/IconBusinessBlack.svg'
import SolutionsList from '../Common/SolutionsList/SolutionsList'
import Strengths from '../MainPage/Strengths/Strengths'
import clientReviewsData from '../../TestData/clientReviewsData.js'

const Business = ({ isMobile }) => {
  return (
    <>
        <CompetenceHead
            title={'Pattern Business'}
            description={'Разрабатываем интерактивные инсталляции для музеев, выставок, шоу-румов и мероприятий'}
            icon={BusinessIcon}
            color={'#FFDC26'}
            topLabel={'Опыт с 2017 года'}
            bottomLabel={'Более 70% клиентов обращаются к нам повторно'}
            isMobile={isMobile}
        />
        <SolutionsList />
        <Strengths data={clientReviewsData} isReview={true} />
    </>
  )
}

export default Business