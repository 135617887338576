import React from 'react'
import ContactsHead from './ContactsHead/ContactsHead'
import ContactsWriteUs from './ContactsWriteUs/ContactsWriteUs'

const Contacts = ({ isMobile }) => {
  return (
    <>
        <ContactsHead isMobile={isMobile} />
        <ContactsWriteUs />
    </>
  )
}

export default Contacts