import './App.css';
import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import MainPage from '../MainPage/MainPage';
import { Routes, Route } from 'react-router-dom';
import Interactive from '../Interactive/Interactive';
import Online from '../Online/Online';
import Xr from '../XR/Xr';
import Business from '../Business/Business';
import Header from '../Common/Header/Header';
import CasePage from '../CasePage/CasePage';
import Footer from '../Common/Footer/Footer';
import Contacts from '../Contacts/Contacts';

function App() {

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileS, setIsMobileS] = useState(false);

  useEffect(() => {
    if (windowSize < 960) {
      setIsMobile(true)
    } else if (windowSize > 959) {
      setIsMobile(false)
    }
    if (windowSize < 744) {
      setIsMobileS(true)
    } else if (windowSize > 743) {
      setIsMobileS(false)
    }
  }, [windowSize])

  const onChange = useCallback(
    debounce(() => {
      setWindowSize(window.innerWidth);
    }, 500),
    [windowSize, setWindowSize]
  );

  useEffect(() => {
    window.addEventListener('resize', onChange);
  }, [onChange]);

  return (
    <div className="App">
      <Header isMobile={isMobile}/>
      <Routes>
        <Route path='/' element={<MainPage isMobile={isMobile} />}></Route>
        <Route path='/interactive' element={<Interactive isMobile={isMobile} />}></Route>
        <Route path='/online' element={<Online isMobile={isMobile} />}></Route>
        <Route path='/xr' element={<Xr isMobile={isMobile} />}></Route>
        <Route path='/business' element={<Business isMobile={isMobile} />}></Route>
        <Route path='/case' element={<CasePage isMobile={isMobile} isMobileS={isMobileS}/>}></Route>
        <Route path='/contacts' element={<Contacts isMobile={isMobile}/>}></Route>
      </Routes>
      <Footer isMobile={isMobile}/>
    </div>
  );
}

export default App;
