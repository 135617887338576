import React from 'react'
import { Link } from 'react-router-dom'
import './CaseHeader.css'
import logoWhite from '../../../Media/logoWhite.svg'
import Navigation from '../../Common/Navigation/Navigation'

const CaseHeader = ({ img, isMobile }) => {
  return (
    <section className='caseHeader container'>
        <div className='caseHeader__banner'>
            <img src={img} alt='case-img' className='caseHeader__img'/>
            {!isMobile &&
            <>
                <Link to='/'><img src={logoWhite} alt='logo' className='caseHeader__logo'/></Link>
                <Navigation isWhite={true}/>
            </>
            }
        </div>
    </section>
  )
}

export default CaseHeader