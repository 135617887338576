import comm from '../TestData/StrengthsIcons/CommunicationIcon.svg'
import approach from '../TestData/StrengthsIcons/ApproachIcon.svg'
import exp from '../TestData/StrengthsIcons/ExpertiseIcon.svg'
import stand from '../TestData/StrengthsIcons/StandartsIcon.svg'
import terms from '../TestData/StrengthsIcons/TermsIcon.svg'

const strengthsData = {
    head: {title: 'Наши сильные стороны', text: 'То, что нас отличает от других'},
    cards: [
        {
            title: 'Коммуникация',
            icon: comm,
            text: `Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»,
                    демонстрирующее проект АЭС «АККУЮ» и устройство ядерного реактора технологии
                    ВВЭР-1200 при помощи технологий дополненной.`,
        },
        {
            title: 'Нестандартный подход к решению задач',
            icon: approach,
            text: `Павильон «Цветоводство» (ВДНХ), Музей Победы на Поклонной горе – экспозиции
                    «Подвиг народа» и «Битва за Москву», Экоцентр на Яузе, шоу-рум Алмаз-Антей.`,
        },
        {
            title: 'Междисциплинарная экспертиза',
            icon: exp,
            text: `Павильон «Цветоводство» (ВДНХ), Музей Победы на Поклонной горе – экспозиции
                    «Подвиг народа» и «Битва за Москву», Экоцентр на Яузе, шоу-рум Алмаз-Антей.`,
        },
        {
            title: 'Реализация в сжатые сроки',
            icon: terms,
            text: `Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»,
                    демонстрирующее проект АЭС «АККУЮ» и устройство ядерного реактора технологии
                    ВВЭР-1200 при помощи технологий дополненной.`,
        },
        {
            title: 'Высокие стандарты качества',
            icon: stand,
            text: `Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»,
                    демонстрирующее проект АЭС «АККУЮ» и устройство ядерного реактора технологии
                    ВВЭР-1200 при помощи технологий дополненной.`,
        },
    ]
}

export default strengthsData