import React, { useState } from 'react'
import './Promo.css'

const Promo = () => {

  const [hasVideo, setHasVideo] = useState(false)

  return (
    <section className='promo container'>
        <div className='promo__wrapper'>
            <div className='promo__content'>
                <h2>Промо-ролик</h2>
                <p>Видео-презентация работы викторины</p>
                <div className='promo__slider'></div>
            </div>
            <div className='promo__inf'>
                <div className='promo__result'>
                    <h2>Результат:</h2>
                    <p>
                        Перед нами стояла задача разработать мобильное приложение для ГК «Росатом»,
                        демонстрирующее проект АЭС «АККУЮ» и устройство ядерного реактора технологии
                        ВВЭР-1200 при помощи технологий дополненной.
                    </p>
                </div>
                <div className='promo__tools'>
                    <h2 className='promo__tools-title'>Инструменты:</h2>
                    <ul className='promo__tools-list'>
                        <li className='promo__tool'>Figma</li>
                        <li className='promo__tool'>Adobe Photoshop</li>
                        <li className='promo__tool'>Unreal Engine</li>
                        <li className='promo__tool'>Notepad</li>
                        <li className='promo__tool'>Calendar</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Promo