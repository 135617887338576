import React, { useEffect, useState } from 'react'
import './RateCase.css'
import qr from '../../../Media/PresentationQr.svg'

const RateCase = ({ isMobileS }) => {

  const [grade, setGrade] = useState(undefined)
  const [isDisabled, setIsDisabled] = useState(true)
  const [hasGrade, setHasGrade] = useState(false)

  const applyGrade = () => {
    setHasGrade(true)
    console.log(grade)
  }

  const resultGradeStyle = () => {
    switch (grade) {
      case 1:
        return 'rateCase__grade-one';
      case 2:
        return 'rateCase__grade-two';
      case 3:
        return 'rateCase__grade-three';
      case 4:
        return 'rateCase__grade-four';
      case 5:
        return 'rateCase__grade-five';
      default:
        break;
      
    }
  }

  useEffect(() => {
    if(grade) {
        setIsDisabled(false)
    }
  }, [grade])

  return (
    <section className='rateCase container'>
        <div className='rateCase__wrapper'>
          {
            hasGrade && isMobileS
            ? <div className='rateCase__rusult'>
                <h2>Спасибо за оценку</h2>
                <p>Росатом VVER-1200</p>
                <div className={`rateCase__rusult-grade ${resultGradeStyle()} grade-selected`}></div>
                <p className='rateCase__rusult-text'>Нам действительно важно ваше мнение, оно помогает нам развиваться!</p>
              </div>
            : <>
                <div className='rateCase__specification'>
                    <h2>Оцените кейс</h2>
                    <p>
                        Мы разработали специальну систему оценки рейтинга кейса.
                        Теперь она доступна всем пользователям, лишь стоит навести
                        мобильное устройство на QR-код справа.
                    </p>
                </div>
                <div className='rateCase__rating'>
                    {isMobileS
                    ? <div className='rateCase__rating-vote'>
                        <div className='rateCase__rating-grade'>
                            <div className={`rateCase__grade-one ${grade === 1 ? 'grade-selected' : ''}`} onClick={() => setGrade(1)}></div>
                            <div className={`rateCase__grade-two ${grade === 2 ? 'grade-selected' : ''}`} onClick={() => setGrade(2)}></div>
                            <div className={`rateCase__grade-three ${grade === 3 ? 'grade-selected' : ''}`} onClick={() => setGrade(3)}></div>
                            <div className={`rateCase__grade-four ${grade === 4 ? 'grade-selected' : ''}`} onClick={() => setGrade(4)}></div>
                            <div className={`rateCase__grade-five ${grade === 5 ? 'grade-selected' : ''}`} onClick={() => setGrade(5)}></div>
                        </div>
                        <div className={`rateCase__rating-btn ${isDisabled ? 'rating-btn-disabled' : ''}`} onClick={applyGrade}>Голосовать</div>
                      </div>
                    :<img src={qr} alt='rate-the-case-qr' className='rateCase__rating-qr'/> 
                    }
                    <p className='rateCase__rating-label'>Оценка кейса пользователями сайта:</p>
                    <p className='rateCase__rating-value'>4.6</p>
                </div>
             </>
          }
            
        </div>
    </section>
  )
}

export default RateCase