import React from 'react'
import CompetenceHead from '../Common/СompetenceHead/СompetenceHead'
import XrIcon from '../../Media/IconXRBlack.svg'
import CaseList from '../Common/CaseList/CaseList'
import WriteUs from '../Common/WriteUs/WriteUs'

const Xr = ({ isMobile }) => {
  return (
    <>
        <CompetenceHead
            title={'Pattern XR'}
            description={'Разрабатываем интерактивные инсталляции для музеев, выставок, шоу-румов и мероприятий'}
            icon={XrIcon}
            color={'#5FFFD8'}
            topLabel={'Опыт с 2017 года'}
            bottomLabel={'Более 70% клиентов обращаются к нам повторно'}
            isMobile={isMobile}
        />
        <CaseList isMobile={isMobile} />
        <WriteUs isMobile={isMobile} />
    </>
  )
}

export default Xr