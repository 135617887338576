import React from 'react'
import './FeedbackForm.css'

const FeedbackForm = () => {

  return (
    <div className='feedbackForm'>
        <div className='feedbackForm__heading'>
            <h3 className='feedbackForm__heading-title'>Тема</h3>
            <span className='feedbackForm__heading-request'>Запрос</span>
        </div>
        <form>
            <div className='feedbackForm__input-wrapper'>
              <input type='text' placeholder='Имя' className='feedbackForm__input'></input>
              <span className='feedbackForm__required'>*</span>
            </div>
            <div className='feedbackForm__input-wrapper'>
              <input type='text' placeholder='Email' className='feedbackForm__input'></input>
              <span className='feedbackForm__required'>*</span>
            </div>
            <div className='feedbackForm__input-wrapper'>
              <input type='text' placeholder='Телефон' className='feedbackForm__input'></input>
            </div>
            <div className='feedbackForm__input-wrapper textarea'>
              <textarea type='text' placeholder='Ваш запрос' rows='9' className='feedbackForm__input'></textarea>
              <span className='feedbackForm__required'>*</span>
            </div>
        </form>
        <div className='feedbackForm__bottom'>
          <p className='feedbackForm__rules'>
            Нажимая на кнопку отправки сообщения, Вы соглашаетесь с <a href='#'>Правилами обработки данных</a> и <a href='#'>Политикой конфеденциальности</a>
          </p>
          <div className='feedbackForm__button'></div>
        </div>
    </div>
  )
}

export default FeedbackForm