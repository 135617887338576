import React from 'react'
import { Link } from 'react-router-dom'
import './WriteUs.css'

const WriteUs = ({ isMobile }) => {
  return (
    <section className='writeUs container'>
        <div className='writeUs__wrapper'>
            <div className='writeUs__title'>
                <h2>Есть вопросы?</h2>
                <p>Свяжитесь с нами и мы ответим на любые вопросы.</p>
            </div>
            <div className='writeUs__bottom'>

                <Link to='/' className='writeUs__btn'>Написать нам</Link>
                {!isMobile && 
                    <div className='writeUs__cutaway'>
                        <p>Элетронная визитка Pattern digital</p>
                        <div className='writeUs__qr-code'></div>
                    </div>
                }
            </div>
        </div>
    </section>
  )
}

export default WriteUs