import porsche from './PartnerLogos/PORSCHE.svg'
import mers from './PartnerLogos/Mercedes-Benz.svg'
import mir from './PartnerLogos/МИР.svg'
import sber from './PartnerLogos/СБЕР.svg'
import moex from './PartnerLogos/МОЕХ Московская биржа.svg'
import gazprom from './PartnerLogos/Газпром.svg'
import expo from './PartnerLogos/EXPO2020 DUBAI UAE.svg'
import lime from './PartnerLogos/Лайм.svg'
import gubaha from './PartnerLogos/ВК Губаха.svg'
import muzeyPodedy from './PartnerLogos/Музей Победы.svg'
import nartis from './PartnerLogos/Нартис.svg'
import rostech from './PartnerLogos/РОСТЕХ.svg'
import rosatom from './PartnerLogos/РОСАТОМ.svg'
import voentelecom from './PartnerLogos/Воентелеком.svg'
import moscovZoo from './PartnerLogos/Московский зоопарк.svg'
import goethe from './PartnerLogos/Институт Гётте.svg'
import yandex from './PartnerLogos/Yandex.svg'
import kronshtad from './PartnerLogos/Кронштадт.svg'
import lucoil from './PartnerLogos/Лукойл.svg'
import novatech from './PartnerLogos/Новатэк.svg'
import totalEnergies from './PartnerLogos/Total Energies.svg'
import teoxane from './PartnerLogos/TEOXANE.svg'
import vse from './PartnerLogos/Высшая школа экономики.svg'
import turbozavri from './PartnerLogos/Турбозавры.svg'
import amirig from './PartnerLogos/Amirig.svg'
import vdnh from './PartnerLogos/ВДНХ.svg'
import gotomuseum from './PartnerLogos/gotomuseum.svg'
import seg from './PartnerLogos/Simpateka Enrertainment Group.svg'
import sanofi from './PartnerLogos/sanofi.svg'
import em from './PartnerLogos/Экономика Москвы.svg'
import vsk from './PartnerLogos/ВСК Страховой Дом.svg'
import ktrv from './PartnerLogos/КТРВ.svg'
import pochta from './PartnerLogos/Почта Доверия.svg'


const partnerLogos = [porsche, mers, mir, sber, moex, gazprom, expo, lime, gubaha, muzeyPodedy, nartis,rostech,
                        rosatom, voentelecom, moscovZoo, goethe, yandex, kronshtad, lucoil, novatech, totalEnergies,
                        teoxane, vse, turbozavri, amirig, vdnh, gotomuseum, seg, sanofi, em, vsk, ktrv, pochta]


export default partnerLogos