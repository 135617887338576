import React from 'react'
import ContactBlock from './ContactBlock/ContactBlock'
import LineUp from './LineUp/LineUp'
import './MainPage.css'
import Strengths from './Strengths/Strengths'
import TopBlock from './TopBlock/TopBlock'
import strengthsData from '../../TestData/strengthsData.js'

const MainPage = ({isMobile}) => {
  return (
    <>
        <TopBlock isMobile={isMobile} />
        <LineUp />
        <Strengths data={strengthsData} />
        <ContactBlock isMobile={isMobile} />
    </>
  )
}

export default MainPage