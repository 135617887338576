import React from 'react'
import './ContactsWriteUs.css'
import FeedbackForm from '../../Common/FeedbackForm/FeedbackForm'

const ContactsWriteUs = () => {
  return (
    <section className='contactsWriteUs container'>
        <div className='contactsWriteUs__wrapper'>
            <div className='contactsWriteUs__info'>
                <div className='contactsWriteUs__title'>
                    <h2>Есть вопросы?</h2>
                    <p>Получите предварительную калькуляцию вашего проекта в течение 12 часов:</p>
                </div>
                <div className='contactsWriteUs__data'>
                    <a href='tel: +79099498853' className='contactsWriteUs__phone'>+7 909 949 88 53</a>
                    <a href='mailto: pro@patterndigital.com' className='contactsWriteUs__email'>pro@patterndigital.com</a>
                </div>
            </div>
            <FeedbackForm />
        </div>
    </section>
  )
}

export default ContactsWriteUs