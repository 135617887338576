import React from 'react'
import CaseHeader from './CaseHeader/CaseHeader'
import caseImg from '../../Media/CaseHeadImg.png'
import CaseInfo from './CaseInfo/CaseInfo'
import RateCase from './RateCase/RateCase'
import Promo from './Promo/Promo'

const CasePage = ({ isMobile, isMobileS }) => {
  return (
    <>
        <CaseHeader isMobile={isMobile} img={caseImg} />
        <CaseInfo isMobile={isMobile} />
        <Promo />
        <RateCase isMobileS={isMobileS} />
    </>
  )
}

export default CasePage