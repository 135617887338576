import React from 'react'
import { Link } from 'react-router-dom'
import './CaseCard.css'
import caseCardImg from '../../../Media/CaseCardImg.png'
import rosatomIcon from '../../../Media/RosatomIcon.svg'

const CaseCard = ({ bgColor, type }) => {
  return (
    <div className='caseCard' style={{backgroundColor: `${bgColor}`}}>
        <Link to='/case'>
            <div className='caseCard__head'>
                <img src={rosatomIcon} alt='img' className='caseCard__logo'/>
                <img src={caseCardImg} alt='img' className='caseCard__head-img'/>
            </div>
            <div className='caseCard__body'>
                <div className='caseCard__mid'>
                    <div className='caseCard__name'>
                        <h3>Росатом</h3>
                        {type !== 'readyMechanics' && <p>2019</p>}
                    </div>
                    {type === 'readyMechanics'
                    ?
                        <div className='caseCard__ready-mechanics'>
                            <p>Готово</p>
                            <div className='caseCard__on-ready'></div>
                        </div>
                    :
                        <div className='caseCard__rating'>
                            <p>4.6</p>
                            <div className='caseCard__rating-star'></div>
                        </div>
                    }
                </div>
                <p className='caseCard__description'>
                    Павильон «Цветоводство» (ВДНХ), Музей Победы на Поклонной горе – экспозиции
                    «Подвиг народа» и «Битва за Москву», Экоцентр на Яузе, шоу-рум Алмаз-Антей в
                    Парке Патриот
                </p>
            </div>
        </Link>
    </div>
  )
}

export default CaseCard